import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import PropTypes from 'prop-types';
import logo from '~/assets/tim_logo_white.svg';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    height: '100%',
    backgroundColor: '#E5E5E5',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  root: {
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#00408C',
  },
  content: {
    marginTop: 150,
  },
}));

const Topbar = (props) => {
  const { children, className, onSidebarOpen, ...rest } = props;

  const classes = useStyles();

  return (
    <div className={classes.card}>
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <div>
          <img        
          src={logo}
          alt='Tim' />
        </div>
      </Toolbar>
    </AppBar>
    <div className={classes.content}>{children}</div>
  </div>
  );
};

Topbar.propTypes = {
  children: PropTypes.node,
};
export default Topbar;
