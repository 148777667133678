import axios from 'axios';

const WEB = 'WEB';
const baseURL = process.env.REACT_APP_BASE_URL;
const responseType = 'json';

const api = axios.create({
  baseURL,
  responseType,
});

api.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem('token');
    config.headers.client = WEB;
    if (authToken && typeof authToken === 'string') {
      config.headers.Authorization = `Bearer ${authToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => {
    if (!response.headers.Authorization) {
      response.headers.Authorization = response.data && response.data.token;
    }
    if (response.headers.Authorization) {
      localStorage.setItem('token', response.headers.Authorization);
    }
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('persist:messenger-adm');
      window.location.reload();
    }
    return Promise.reject(error.response);
  }
);

export default api;
