import React from 'react';
import { CardHeader, Card } from '@material-ui/core';
import SaveCampaign from '../SaveCampaign';
import { Skeleton } from '@material-ui/lab';

const CampaignConfigs = ({ campaign, loading }) => {
  return (
    <Card
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: '15px',
        alignItems: 'center',
      }}
    >
      {loading ? (
        <Skeleton
          variant='text'
          width={220}
          height={50}
          style={{ marginLeft: '15px' }}
          animation='wave'
        />
      ) : (
        <CardHeader title={campaign?.name} />
      )}
      <SaveCampaign />
    </Card>
  );
};

export default CampaignConfigs;
