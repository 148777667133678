import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {  CardContent } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { useStyles } from '../styles';

const styles = makeStyles(() => ({
  card: {
    alignItems: 'center',
    textAlign: 'left',
    padding: '16px 24px',
    height: 172,
    width: 434
  },
}));

const pageConfig = {
  route: "/descadastrar/email/sucesso/",
}

function SuccessUnsubscribe () {
  const classesStyles = styles();
  const classes = useStyles();

  useEffect(() => {
    window.history.pushState(pageConfig, "")
  })

  return (
    <Card className={classesStyles.card}>
    <CardContent style={{padding: '0px'}}>
      <h1 className={classes.h1}>
      Pronto! Você foi cadastrado novamente em nossa lista de emails.
      </h1>
      <p className={classes.p}>
      Não se preocupe, você continuará a receber nossos emails promocionais.
      </p>
      <div className={classes.span}>
      </div>
    </CardContent>
    </Card>
  );
}
export default SuccessUnsubscribe;
