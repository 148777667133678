import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import logo from '~/assets/Omega_MessageCenter_iD_R2May082020-08.png';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import { signInRequest } from '~/store/modules/auth/actions';
const schema = Yup.object().shape({
  email: Yup.string().email('Email inválido').required('Campo obrigatório'),
  password: Yup.string().required('Campo Obrigatório'),
});

function SignIn() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  const handleSubmit = ({ email, password }) => {
    dispatch(signInRequest(email, password));
  };
  return (
    <>
      <img
        src={logo}
        alt='MessageCenter'
        style={{ height: '250px', marginBottom: '-35px' }}
      />
      <Form onSubmit={handleSubmit} schema={schema}>
        <Input
          name='email'
          autoComplete='off'
          type='email'
          placeholder='Seu e-mail'
        />
        <Input
          autoComplete='off'
          name='password'
          type='password'
          placeholder='Password'
        />
        <button type='submit'>{loading ? 'Carregando ...' : 'Acessar'}</button>
      </Form>
    </>
  );
}

export default SignIn;
