import React, { useState, useCallback } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core/';

import api from '~/services/api';
import { toast } from 'react-toastify';

const DialogEditBatches = ({
  openDialogEdit,
  batches,
  setBatchSelect,
  handleUpdateBatches,
}) => {
  const [message, setMessage] = useState(batches?.message);

  const MAX_LENGTH = 160;
  const helperText = useCallback((message) => {
    return message.length > MAX_LENGTH
      ? `Messagem deve ter até ${MAX_LENGTH} caracteres.`
      : `${MAX_LENGTH - message.length} caracteres disponiveis`;
  }, []);

  const handleValidateText = useCallback((message) => {
    return message.length > MAX_LENGTH ? true : false;
  }, []);

  const handleSaveBatch = () => {
    const data = {
      batch_form: {
        message: message,
      },
    };

    api
      .put(`/campanha/lote/${batches.id}`, data)
      .then(({ data }) => {
        setBatchSelect(null);
        toast.success('Mensagem do lote alterada com sucesso!');
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao editar a mensagem do lote.');
      });

    api.get(`/campanha/${batches.campaign_id}`).then(({ data }) => {
      handleUpdateBatches(data.data.batches);
    });
  };

  return (
    <div>
      {batches && (
        <Dialog
          fullWidth
          maxWidth='sm'
          open={openDialogEdit}
          onClose={() => setBatchSelect(null)}
          aria-labelledby={`edit-batch_${batches.id}`}
          key={batches.id}
        >
          <DialogTitle id={`edit-batch_${batches.id}`}>
            Lotes de envio de mensagens
          </DialogTitle>
          <DialogContent style={{ padding: '0 16px' }}>
            <TextField
              fullWidth
              multiline
              rows={3}
              label='Mensagem'
              margin='dense'
              value={message}
              error={handleValidateText(message)}
              helperText={helperText(message)}
              onChange={(e) => setMessage(e.target.value)}
              variant='outlined'
              required
            />
          </DialogContent>
          <DialogActions style={{ flex: 1, padding: '0  16px  16px' }}>
            <Button
              onClick={() => setBatchSelect(null)}
              style={{ marginRight: 'auto' }}
            >
              Cancel
            </Button>
            <Button
              variant='outlined'
              onClick={() => handleSaveBatch()}
              color='primary'
            >
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default DialogEditBatches;
