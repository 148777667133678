import React, { useEffect } from 'react';
import { Card, CardHeader } from '@material-ui/core';
import useStyles from './makeStyles';
import CampaignConfigs from './components/CampaignConfigs';
import Batches from './components/Batches';
import SaveCampaign from './components/SaveCampaign';
import { useDispatch } from 'react-redux';
import { clearCampaign } from '~/store/modules/campaign/actions';
function Campaign() {
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearCampaign());
  }, [dispatch]);
  return (
    <Card>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginRight: '18px',
          alignItems: 'center',
        }}
      >
        <CardHeader title='Campanha' subheader='Informações da campanha' />
        <SaveCampaign />
      </div>
      <Card>
        <Card className={classes.card}>
          <form autoComplete='off'>
            <CampaignConfigs />
          </form>
        </Card>
        <Card className={classes.card}>
          <Batches />
        </Card>
      </Card>
    </Card>
  );
}

export default Campaign;
