import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import api from '~/services/api';
import { ButtonProgress } from './styles';
import { clearCampaign } from '~/store/modules/campaign/actions';
import history from '~/services/history';
import { toast } from 'react-toastify';

const SaveCampaign = () => {
  const campaign = useSelector((state) => state.campaign);
  const dispatch = useDispatch();
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const valid = campaign.batches?.reduce((valid, current) => {
      return (
        valid &&
        current.message &&
        current.message.length < 161 &&
        current.contacts instanceof File
      );
    }, !!campaign.name);
    setIsValid(valid);
  }, [campaign]);
  const formatForSave = () => {
    let data = new FormData();
    data.append('name', campaign.name);
    data.append('send_method', campaign.sendMethod);
    campaign.batches.forEach((batch) => {
      data.append(`campaign-batches-messages[]`, batch.message);
      data.append(`campaign-batches-send_to[]`, batch.contacts);
    })

    return data;
  };
  const save = () => {
    const data = formatForSave();
    setLoading(true);
    api
      .post('/campanha', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    })
      .then((response) => {
        const campaignId = response.data.data.id
        dispatch(clearCampaign());
        history.push(`/campanhas/${campaignId}`);
      })
      .catch(() => {
        toast.error('Erro ao criar uma campanha, verifique os dados');
        setLoading(false);
      });
  };
  return (
    <>
      <Button
        variant='contained'
        onClick={() => {
          save();
        }}
        color='primary'
        disabled={!isValid || loading}
        startIcon={<Save />}
      >
        Salvar
        {loading && <ButtonProgress size={24} />}
      </Button>
    </>
  );
};

export default SaveCampaign;
