import produce from 'immer';
const reducers = {
  signInSuccess(state, payload) {
    return produce(state, (draft) => {
      draft.user = payload.user;
    });
  },
  signOut(state) {
    return produce(state, (draft) => {
      draft.user = {};
    });
  },
};
export default reducers;
