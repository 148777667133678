import React, { useEffect, useState } from 'react';
import { Card, CardHeader, Divider, Button, colors } from '@material-ui/core';
import api from '~/services/api';
import Dashs from './components/Dashs';
import { Skeleton } from '@material-ui/lab';
import Batches from './components/Batches';
import ContactTable from './components/ContactTable';
import { makeStyles } from '@material-ui/styles';
import { EditSharp } from '@material-ui/icons';
import history from '~/services/history';
const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: '15px',
    padding: '10px',
  },
  session: {
    width: '100%',
    marginTop: '15px',
  },
  campaignHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '10px',
    alignItems: 'center',
  },
});
const Detail = ({ match }) => {
  const classes = useStyles();
  const id = match.params.id;
  const [campaign, setCampaign] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.get(`/campanha/${id}`).then(({ data }) => {
      setCampaign(data.data);
      setLoading(false);
    });
  }, [id]);

  return (
    <Card className={classes.root}>
      <div className={classes.campaignHeader}>
        <CardHeader title='Campanha' subheader='Informações da campanha' />
        <Button
          style={{ background: colors.yellow['700'], color: '#fff' }}
          variant='contained'
          component='span'
          onClick={() => history.push(`/campanhas/${id}/editar`)}
          startIcon={<EditSharp />}
        >
          Adicionar Lote
        </Button>
      </div>

      <Divider />

      <Card>
        {loading ? (
          <Skeleton variant='text' style={{ margin: '10px', width: '250px' }} />
        ) : (
          <CardHeader title={campaign.name} />
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            margin: '10px',
            padding: '5px',
          }}
        >
          <Dashs campaign={campaign} loading={loading} />
        </div>
      </Card>
      <Batches batches={campaign.batches} loading={loading} />

      {!loading && <ContactTable campaign={campaign} />}
    </Card>
  );
};

export default Detail;
