import styled from 'styled-components';
import { Card } from '@material-ui/core';

export const HeaderBatches = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
`;
export const Batches = styled(Card)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin: 10px;
  input[type='file'] {
    display: none;
  }
`;
