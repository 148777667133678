import React from 'react';
import { FiberNew, NearMe, Textsms, Error } from '@material-ui/icons';

export default {
  NOVO: {
    label: 'Novo',
    color: '#00bcd4',
    icon: <FiberNew />,
  },
  ENVIADO: {
    label: 'Enviado',
    color: '#2196f3',
    icon: <NearMe />,
  },
  FALHA: {
    label: 'Falha',
    color: '#f44336',
    icon: <Error />,
  },
  LIDO: {
    label: 'Lido',
    color: '#009688',
    icon: <Textsms />,
  },
  INVALIDO: {
    label: 'Inválido',
    color: '#f44336',
    icon: <Error />,
  },
};
