export default {
  ENVIADO: {
    label: 'Enviado',
  },
  NAO_PROCESSADO: {
    label: 'Não processado',
  },
  PROCESSANDO: {
    label: 'Processando',
  },
  PROCESSADO: {
    label: 'Processado',
  },
};