export function updateCampaignName(name) {
  return {
    type: 'updateCampaignName',
    payload: name,
  };
}
export function updateCampaignSendMethod(sendMethod) {
  return {
    type: 'updateCampaignSendMethod',
    payload: sendMethod,
  };
}
export function clearCampaign(name) {
  return {
    type: 'clearCampaign',
    payload: {},
  };
}
export function addCampaignBatches(batches) {
  return {
    type: 'addCampaignBatches',
    payload: batches,
  };
}
export function addCampaignNewBatches(batches) {
  return {
    type: 'addCampaignNewBatches',
    payload: batches,
  };
}
export function removeCampaignBatches(index) {
  return {
    type: 'removeCampaignBatches',
    payload: index,
  };
}
export function removeCampaignNewBatches(index) {
  return {
    type: 'removeCampaignNewBatches',
    payload: index,
  };
}
export function updateCampaignMsgBatchIndex(index, message) {
  return {
    type: 'updateCampaignMsgBatchIndex',
    payload: { index, message },
  };
}
export function updateCampaignMsgNewBatchIndex(index, message) {
  return {
    type: 'updateCampaignMsgNewBatchIndex',
    payload: { index, message },
  };
}
export function updateCampaignContactsBatchIndex(index, contacts) {
  return {
    type: 'updateCampaignContactsBatchIndex',
    payload: { index, contacts },
  };
}
export function updateCampaignContactsNewBatchIndex(index, contacts) {
  return {
    type: 'updateCampaignContactsNewBatchIndex',
    payload: { index, contacts },
  };
}
export function loadCampaign(campaign) {
  return {
    type: 'loadCampaign',
    payload: campaign,
  };
}
