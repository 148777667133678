import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
export default makeStyles(() => ({
  root: {
    padding: '10px',
  },
  card: {
    marginBottom: '15px',
  },
  input: {
    display: 'none',
  },
  campaignHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: '20px',
  },
  deletContact: {
    color: colors.red[400],
  },
}));
