import React, { useState, useEffect } from 'react';
import {
  CardHeader,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  IconButton,
  TablePagination,
  InputAdornment,
  FormControl,
  OutlinedInput,
  InputLabel,
} from '@material-ui/core';
import {
  AddCircleOutlineOutlined as AddCircleOutlineOutlinedIcon,
  Search,
  VisibilityOutlined as VisibilityOutlinedIcon,
} from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { parseISO, format } from 'date-fns';
import useStyles from './makeStyles';
import history from '~/services/history';
import api from '~/services/api';

function Campaign() {
  const classes = useStyles();
  const [filter, setFilter] = useState('');
  const [campaigns, setCampaigns] = useState([]);
  const [filterdCampaigns, setFilterdCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    api
      .get('/campanha?active=1')
      .then(({ data }) => {
        setCampaigns(data.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setPage(0);
    setFilterdCampaigns(() => {
      return filter
        ? campaigns?.filter((campaign) =>
            campaign.name.toLowerCase().includes(filter.toLowerCase())
          )
        : campaigns;
    });
  }, [filter, campaigns]);

  return (
    <div className={classes.root}>
      <div className={classes.campaignHeader}>
        <CardHeader title="Campanha" subheader="Lista das Campanhas" />
        <FormControl
          variant="outlined"
          margin="none"
          size="small"
          fullWidth
          style={{ width: '45%' }}
        >
          <InputLabel>Buscar Campanha</InputLabel>
          <OutlinedInput
            value={filter}
            onChange={(e) => setFilter(e.target.value.trim())}
            endAdornment={
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            }
            labelWidth={120}
          />
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          component="span"
          onClick={() => history.push('/campanhas/nova')}
          startIcon={<AddCircleOutlineOutlinedIcon />}
        >
          Nova campanha
        </Button>
      </div>
      <Divider />
      <TableContainer style={{ marginTop: '15px' }} component={Paper}>
        <Table aria-label="table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell align="center">Método de Envio</TableCell>
              <TableCell align="center">Data de Criação</TableCell>
              <TableCell align="center">Lotes</TableCell>
              <TableCell align="right">
                <span style={{ marginRight: '35px' }}>Ações</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading
              ? [1, 2, 3, 4, 6, 7, 8, 9, 10].map((item) => (
                  <TableRow style={{ height: 60 }} key={item}>
                    <TableCell>
                      <Skeleton animation="wave" variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" variant="text" />
                    </TableCell>
                  </TableRow>
                ))
              : filterdCampaigns
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((campaign) => (
                    <TableRow style={{ height: 35 }} key={campaign.id}>
                      <TableCell component="th" scope="row">
                        {campaign.name}
                      </TableCell>
                      <TableCell align="center">
                        {campaign.send_method}
                      </TableCell>
                      <TableCell align="center">
                        {format(parseISO(campaign.created_at), 'dd/MM/yyyy')}
                      </TableCell>
                      <TableCell align="center">
                        {campaign.number_of_batches}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          color="primary"
                          aria-label="sVisibilityOutlined"
                          onClick={() =>
                            history.push(`/campanhas/${campaign.id}`)
                          }
                        >
                          <VisibilityOutlinedIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={filterdCampaigns.length}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage="Iténs por páginas"
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}

export default Campaign;
