import produce from 'immer';
import history from '~/services/history';
const reducers = {
  signInRequest(state) {
    return produce(state, (draft) => {
      draft.loading = true;
    });
  },
  signUpRequest(state) {
    return produce(state, (draft) => {
      draft.loading = true;
    });
  },
  signOut(state) {
    return produce(state, (draft) => {
      draft.token = null;
      draft.signed = false;
      draft.loading = false;
      history.push('/');
    });
  },
  signInSuccess(state, { token }) {
    return produce(state, (draft) => {
      draft.token = token;
      draft.signed = true;
      draft.loading = false;
    });
  },
  signFailure(state) {
    return produce(state, (draft) => {
      draft.signed = false;
      draft.loading = false;
    });
  },
};
export default reducers;
