import React, { useEffect, useState, useCallback } from 'react';
import {
  CardHeader,
  Typography,
  Tooltip,
  Button,
  IconButton,
} from '@material-ui/core';
import { GetApp, Edit, Delete } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import statusConfig from '~/pages/Campaign/statusConfig';
import batchStatusConfig from '~/pages/Campaign/batchStatusConfig';
import {
  BatchCard,
  BatchData,
  BatchDataItems,
  BatchMessage,
  BatchMessageContainer,
  BatchMessageStatus,
  ReadMarker,
} from '~/pages/Campaign/Detail/components/Batches/styles';

import DialogEditBatches from '~/pages/Campaign/Detail/components/Batches/EditBatchesDialog';
import DialogRemoveBatches from '~/pages/Campaign/Detail/components/Batches/RemoveBatchesDialog';
import api from '~/services/api';
import copyIcon from 'assets/copy.svg';
import { toast } from 'react-toastify';

const Batches = ({ batches, loading }) => {
  const downloadUrl = (url) => window.open(url, '_blank');
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [openDialogRemove, setOpenDialogRemove] = useState(false);
  const [batchRemoveSelect, setBatchRemoveSelect] = useState(null);
  const [batchSelect, setBatchSelect] = useState(null);
  const [batchList, setBatchList] = useState(batches);

  useEffect(() => {
    if (!batches) return;
    setOpenDialogEdit(!!batchSelect);
    setBatchList(batches);
  }, [batchSelect, batches]);

  const onChangeBatchList = useCallback((batchesUpdated) => {
    setBatchList(batchesUpdated);
  }, []);

  const handleUpdateBatchList = useCallback(
    (campaign_id) => {
      api.get(`/campanha/${campaign_id}`).then(({ data }) => {
        onChangeBatchList(data.data.batches);
      });
    },
    [onChangeBatchList]
  );

  const removeBatchCampaign = useCallback(
    (batchId) => {
      api
        .delete(`campanha/lote/${batchId}`)
        .then(({ data }) => {
          handleUpdateBatchList(data.data.campaign_id);
          toast.success('Lote removido com sucesso!');
          setOpenDialogRemove(false);
        })
        .catch(() => {
          toast.error(
            'Erro ao remover um lote, verifique a sua permissão por favor.'
          );
        });
    },
    [handleUpdateBatchList]
  );

  const onClickRemoveDialog = useCallback((batch) => {
    setOpenDialogRemove(true);
    setBatchRemoveSelect(batch);
  }, []);

  const onRemoveDialog = useCallback(() => {
    removeBatchCampaign(batchRemoveSelect);
  }, [batchRemoveSelect, removeBatchCampaign]);

  return (
    <BatchCard>
      <>
        <CardHeader title='Lotes' subheader='Lotes de envio de mensagens' />
        {loading
          ? [1, 2, 3].map((item) => (
              <BatchData key={item}>
                <Skeleton variant='text' />
              </BatchData>
            ))
          : batchList &&
            batchList.map((lot, index) => (
              <BatchData key={lot.id} data={lot}>
                <BatchDataItems>
                  <BatchMessageContainer>
                    <BatchMessage>
                      <p style={{ fontSize: '14px' }}>{lot.message}</p>

                      <IconButton style={{ height: '36px', marginTop: '-8px' }}>
                        <img src={copyIcon} alt='copiar valor' />
                      </IconButton>
                    </BatchMessage>
                    <BatchMessageStatus>
                      {batchStatusConfig[lot.status].label}
                    </BatchMessageStatus>
                    <div className='batchMessageActions'>
                      {batchStatusConfig[lot.status].label ===
                        'Não processado' && (
                        <Button
                          type='button'
                          color='secondary'
                          size='small'
                          style={{ padding: 10 }}
                          startIcon={<Delete />}
                          onClick={() => {
                            onClickRemoveDialog(lot.id);
                          }}
                        >
                          Remover
                        </Button>
                      )}
                      <Button
                        type='button'
                        color='secondary'
                        size='small'
                        style={{ padding: 10 }}
                        startIcon={<Edit />}
                        onClick={() => {
                          setBatchSelect(lot);
                        }}
                      >
                        Editar
                      </Button>
                      <Button
                        type='button'
                        color='secondary'
                        size='small'
                        style={{ padding: 10 }}
                        startIcon={<GetApp />}
                        onClick={() => {
                          downloadUrl(lot.download_url);
                        }}
                      >
                        Baixar
                      </Button>
                    </div>
                  </BatchMessageContainer>
                  <ReadMarker
                    style={{
                      flex: 1,
                      maxWidth: '100%',
                      marginRight: '10px',
                      marginTop: '10px',
                    }}
                  >
                    <Typography variant='body1'>
                      Mensagens Lidas:
                      {(
                        (lot.number_of_send_to_by_status['LIDO'] /
                          lot.number_of_send_to) *
                        100
                      ).toFixed(2)}
                      %
                    </Typography>

                    <div style={{ display: 'flex' }}>
                      {Object.keys(lot.number_of_send_to_by_status).map(
                        (status, index) => {
                          return (
                            <Tooltip
                              key={index}
                              title={`${statusConfig[status].label}:${lot.number_of_send_to_by_status[status]} `}
                            >
                              <div
                                style={{
                                  width: `${
                                    (lot.number_of_send_to_by_status[status] /
                                      lot.number_of_send_to) *
                                    100
                                  }%`,
                                  background: statusConfig[status].color,
                                  height: '15px',
                                }}
                              />
                            </Tooltip>
                          );
                        }
                      )}
                    </div>
                  </ReadMarker>
                </BatchDataItems>
              </BatchData>
            ))}
      </>
      {batchSelect && (
        <DialogEditBatches
          openDialogEdit={openDialogEdit}
          setOpenDialogEdit={setOpenDialogEdit}
          batches={batchSelect}
          setBatchSelect={setBatchSelect}
          handleUpdateBatches={onChangeBatchList}
        />
      )}

      <DialogRemoveBatches
        openDialogRemove={openDialogRemove}
        setOpenDialogRemove={setOpenDialogRemove}
        onRemove={onRemoveDialog}
        batchRemoveSelect={batchRemoveSelect}
        // onRemove={removeBatchCampaign}
      />
    </BatchCard>
  );
};

export default Batches;
