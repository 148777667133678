import React from 'react';
import { Card } from '@material-ui/core';

import Header from './HeaderBatche';
import BatcheItens from './BatcheItens';

const Batches = () => {
  return (
    <Card>
      <Header />
      <BatcheItens />
    </Card>
  );
};

export default Batches;
