import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() => ({
  card: {
    alignItems: 'center',
    textAlign: 'left',
    padding: '16px 24px',
    height: 244,
    width: 434
  },
  h1: {
    color: '#00408C',
    fontWeight: '700',
    fontSize: 22,
    lineHeight: '30px',
    paddingBottom: '16px',
  },
  p: {
    color: '#00408C',
    fontSize: 16,
    fontWeight: '400',
    lineHeight: '24px'
  },
  button: {
    backgroundColor: '#00B32F',
    color: '#FFFFFF',
    width: '100%',
    marginTop: 32,
    borderRadius: 20
  },
  span: {
    color: '#00408C',
    fontSize: 12,
    fontWeight: '400',
    lineHeight: '20px',
    paddingTop: 32,
  },
  link: {
    color: '#00408C',
    textDecoration: 'underline',
    background: 'none!important',
    border: 'none',
    padding: '0!important',
    cursor: 'pointer',
  }
}));