import React from 'react';
import { Card } from '@material-ui/core';

import Header from './Header';
import BatcheItens from './BatcheItens';

const Batches = ({ loading }) => {
  return (
    <Card style={{ margin: '15px' }}>
      <Header loading={loading} />
      <BatcheItens />
    </Card>
  );
};

export default Batches;
