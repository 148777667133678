import { takeLatest, call, put, all } from 'redux-saga/effects';
import api from '~/services/api';
import { toast } from 'react-toastify';
import { signInSuccess, signFailure } from './actions';
import history from '~/services/history';

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;
    const { data } = yield call(api.post, 'auth/login', {
      email,
      password,
    });
    const { token, user } = data;
    yield put(signInSuccess(token, user));
    history.push('/campanhas');
  } catch (error) {
    console.log(error);
    toast.error('Falha na autenticação,verifique seu dados');
    yield put(signFailure());
  }
}

export function* signUp({ payload }) {
  try {
    const { name, email, password } = payload;
    yield call(api.post, 'users', {
      name,
      email,
      password,
      provider: true,
    });
    yield put(signInSuccess(null, null));
    history.push('/');
  } catch (error) {
    toast.error('Falha no cadastro, verificar seu dados!');
    yield put(signFailure());
  }
}
export default all([
  takeLatest('signInRequest', signIn),
  takeLatest('signUpRequest', signUp),
]);
