import React from 'react';

import Button from '@material-ui/core/Button';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core/';

const DialogRemoveBatches = ({
  openDialogRemove,
  setOpenDialogRemove,
  onRemove,
  batchRemoveSelect,
}) => {
  return (
    <div>
      <Dialog
        maxWidth='sm'
        open={openDialogRemove}
        aria-labelledby={`remove-batch_${batchRemoveSelect}`}
        onClose={() => setOpenDialogRemove(false)}
        key={batchRemoveSelect}
      >
        <DialogTitle id='alert-dialog-title'>
          {`Deseja remover o lote ${batchRemoveSelect}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Este processo é irreversivel, caso realizado por engano será
            necessário adicionar o lote manualmente.
          </DialogContentText>
        </DialogContent>

        <DialogActions style={{ flex: 1, padding: '0  16px  16px' }}>
          <Button
            // onClick={() => setBatchSelect(null)}
            onClick={() => setOpenDialogRemove(false)}
            style={{ marginRight: 'auto' }}
          >
            Cancel
          </Button>
          <Button
            variant='outlined'
            autoFocus
            color='primary'
            onClick={() => onRemove()}
          >
            Remover
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogRemoveBatches;
