import styled from 'styled-components';

export const BatchCard = styled.div`
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%);
  margin-top: 25px;
  overflow: hidden;
`;

export const BatchData = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%);
  margin: 10px;
  padding: 16px;
`;

export const BatchDataItems = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const BatchMessageContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;

  .batchMessageActions {
    margin-left: auto;
    justify-content: space-between;
  }

  @media (max-width: 720px) {
    flex-direction: column;
    .batchMessageActions {
      margin: 0;
    }
  }
`;

export const BatchMessage = styled.div`
  display: flex;
  flex: 1;
  max-width: 610px;
  min-width: 150px;
  align-items: flex-start;
`;

export const BatchMessageStatus = styled.div`
  width: 125px;
  font-size: 14px;
  font-weight: 500;
  margin-left: 15px;
  text-align: left;

  @media (max-width: 720px) {
    width: 100%;
    padding: 16px 0;
    text-align: center;
  }
`;

export const ReadMarker = styled.div`
  max-width: 100%;
  margin-top: 10px;
  margin-right: 10px;
`;
