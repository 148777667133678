import React, { useState, useEffect } from 'react';
import { Card } from '@material-ui/core';
import CampaignConfigs from './componets/CampaignConfigs';
import api from '~/services/api';
import { useDispatch, useSelector } from 'react-redux';
import { loadCampaign } from '~/store/modules/campaign/actions';
import Batches from './componets/Batches';

const Edit = ({ match }) => {
  const id = match.params.id;
  const dispach = useDispatch();
  const campaign = useSelector((state) => state.campaign.data);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    api.get(`/campanha/${id}`).then(async ({ data }) => {
      await dispach(loadCampaign(data.data));
      setLoading(false);
    });
  }, [id, dispach]);

  return (
    <Card>
      <CampaignConfigs campaign={campaign} loading={loading} />
      <Batches loading={loading} />
    </Card>
  );
};

export default Edit;
