import React from 'react';
import { CardHeader, Button } from '@material-ui/core';
import { AddCircleOutlineOutlined } from '@material-ui/icons';
import { Header as HeaderContainer } from './styles';
import { addCampaignNewBatches } from '~/store/modules/campaign/actions';
import { useDispatch, useSelector } from 'react-redux';

const Header = ({ loading }) => {
  const campaign = useSelector((state) => state.campaign.data);
  const dispatch = useDispatch();
  const handleAddLote = () => {
    dispatch(
      addCampaignNewBatches([
        ...campaign.newBatches,
        { message: '', contacts: [] },
      ])
    );
  };
  return (
    <HeaderContainer>
      <CardHeader title='Lotes' subheader='Lotes de envio' />
      <Button
        variant='contained'
        color='primary'
        disabled={loading}
        component='span'
        onClick={handleAddLote}
        startIcon={<AddCircleOutlineOutlined />}
      >
        Novo lote
      </Button>
    </HeaderContainer>
  );
};

export default Header;
