import React from 'react';
// import DashboardIcon from '@material-ui/icons/Dashboard';
// import PeopleIcon from '@material-ui/icons/People';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
// import TextFieldsIcon from '@material-ui/icons/TextFields';
// import ImageIcon from '@material-ui/icons/Image';
// import AccountBoxIcon from '@material-ui/icons/AccountBox';
// import SettingsIcon from '@material-ui/icons/Settings';
// import ReceiptOpenIcon from '@material-ui/icons/Receipt';

const page = [
  // {
  //   title: 'Dashboard',
  //   href: '/dashboard',
  //   icon: <DashboardIcon />,
  //   permission: '',
  // },
  {
    title: 'Campanhas',
    href: '/campanhas',
    icon: <ShoppingBasketIcon />,
    permission: '',
  },
  // {
  //   title: 'Usuários',
  //   href: '/usuarios',
  //   icon: <PeopleIcon />,
  //   permission: '',
  // },

  // {
  //   title: 'Vendas',
  //   href: '/vendas',
  //   icon: <ReceiptOpenIcon />,
  //   permission: '',
  // },
  // {
  //   title: 'Typography',
  //   href: '/typography',
  //   icon: <TextFieldsIcon />,
  // },
  // {
  //   title: 'Icons',
  //   href: '/icons',
  //   icon: <ImageIcon />,
  // },
  // {
  //   title: 'Account',
  //   href: '/account',
  //   icon: <AccountBoxIcon />,
  // },
  // {
  //   title: 'Settings',
  //   href: '/settings',
  //   icon: <SettingsIcon />,
  // },
];

export default page;
