import React from 'react';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import './config/ReactotronConfig';
import { ThemeProvider } from '@material-ui/styles';

import theme from './theme';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import Routes from './routes';
import './assets/scss/index.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';

import history from './services/history';

import { store, persistor } from './store';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router history={history}>
            <Routes />
            <ToastContainer autoClose={3000} />
          </Router>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
