import React, {useEffect} from 'react';
import {
  CardHeader,
  Card,
  CardContent,
  Grid,
  TextField,
  InputLabel,
  Select, FormControl,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {clearCampaign, updateCampaignName, updateCampaignSendMethod} from '~/store/modules/campaign/actions';

const CampaignConfigs = () => {
  const dispatch = useDispatch();
  const handleChangeName = (value) => {
    dispatch(updateCampaignName(value));
  };
  const handleChangeSendMethod = (value) => {
    dispatch(updateCampaignSendMethod(value));
  };

  useEffect(() => {
    dispatch(clearCampaign());
  }, [dispatch]);

  return (
    <Card>
      <CardHeader title='Configurações' subheader='Configurações da Campanha' />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              autoComplete='off'
              fullWidth
              label='Nome da Campanha'
              margin='dense'
              name='name'
              onChange={(e) => handleChangeName(e.target.value)}
              required
              variant='outlined'
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth
            >
            <InputLabel htmlFor="method" focused>
              Método de Envio
            </InputLabel>
            <Select
                native
                onChange={(e) => handleChangeSendMethod(e.target.value)}
                label="Método de Envio"
            >
              <option value="tim">Móvel</option>
              <option value="live">Live</option>
              <option value="disparo_pro">Disparo PRO</option>
            </Select>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CampaignConfigs;
