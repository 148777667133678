import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
// const avatarDefault = 'https://api.adorable.io/avatars/50/abott@adorable.png';
import avatarDefault from '~/assets/avatar.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
}));

const Profile = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const { user } = useSelector((state) => state.user);

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Avatar
        alt='Person'
        className={classes.avatar}
        component={RouterLink}
        src={avatarDefault}
        to='/'
      />
      <Typography className={classes.name} variant='h5'>
        {user.name}
      </Typography>
      <Typography variant='body2'>{user.role.name}</Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
};

export default Profile;
