import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import { ButtonProgress } from '~/pages/Campaign/Create/components/SaveCampaign/styles';
import api from '~/services/api';
import { clearCampaign } from '~/store/modules/campaign/actions';
import history from '~/services/history';
import { toast } from 'react-toastify';

const SaveCampaign = () => {
  const campaign = useSelector((state) => state.campaign.data);
  const dispatch = useDispatch();
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const valid = campaign?.newBatches?.reduce((valid, current) => {
      return (
        valid &&
        current.message &&
        current.message.length < 161 &&
        current.contacts instanceof File
      );
    }, !!campaign.name && !!campaign.newBatches?.length);
    setIsValid(valid);
  }, [campaign]);

  const formatForSave = () => {
    let data = new FormData();
    campaign.newBatches.forEach((batch) => {
      data.append(`campaign-batches-messages[]`, batch.message);
      data.append(`campaign-batches-send_to[]`, batch.contacts);
    })

    return data;
  };
  const save = () => {
    const data = formatForSave();
    setLoading(true);
    api
      .post(`/campanha/inserir-lotes/${campaign.id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        setLoading(false);
        dispatch(clearCampaign());
        history.push('/campanhas');
      })
      .catch(() => {
        toast.error('Erro ao criar uma campanha, verifique os dados');
        setLoading(false);
      });
  };

  return (
    <Button
      variant='contained'
      onClick={() => {
        save();
      }}
      color='primary'
      disabled={!isValid || loading}
      startIcon={<Save />}
    >
      Salvar
      {loading && <ButtonProgress size={24} />}
    </Button>
  );
};

export default SaveCampaign;
