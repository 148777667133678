import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  removeCampaignBatches,
  updateCampaignMsgBatchIndex,
  updateCampaignContactsBatchIndex,
} from '~/store/modules/campaign/actions';
import { Batches } from './styles';
import { TextField, Button, colors } from '@material-ui/core';
import { CloudUpload, Delete } from '@material-ui/icons';

const MAX_LENGTH = 160;

const BatcheItens = () => {
  const campaign = useSelector((state) => state.campaign);
  const dispatch = useDispatch();
  const handleDeleteBatches = (index) => {
    dispatch(removeCampaignBatches(index));
  };
  const handleMessege = (index, value) => {
    dispatch(updateCampaignMsgBatchIndex(index, value));
  };
  const handleUpFile = (index, file) => {
    dispatch(updateCampaignContactsBatchIndex(index, file));
  };
  const helperText = (message) => {
    return message.length > MAX_LENGTH
      ? `Messagem deve ter até ${MAX_LENGTH} caracteres.`
      : `${MAX_LENGTH - message.length} caracteres disponiveis`;
  };
  return (
    <>
      {campaign.batches?.map((batch, index) => (
        <div key={index}>
          <Batches>
            <TextField
              style={{ width: '45%' }}
              autoComplete='off'
              fullWidth
              label='Mensagem'
              helperText={helperText(batch.message)}
              margin='dense'
              value={batch.message}
              error={batch.message.length > MAX_LENGTH}
              onChange={(e) => {
                handleMessege(index, e.target.value);
              }}
              required
              variant='outlined'
            />
            <div>
              <input
                accept='.csv'
                id={`contained-button-file-${index}`}
                type='file'
                onChange={(e) => {
                  handleUpFile(index, e.target.files[0]);
                }}
              />
              <label htmlFor={`contained-button-file-${index}`}>
                <Button
                  variant='contained'
                  color='primary'
                  component='span'
                  startIcon={<CloudUpload />}
                >
                  Upload
                </Button>
              </label>
              <Button
                variant='contained'
                onClick={() => handleDeleteBatches(index)}
                style={{
                  marginLeft: '5px',
                  background: colors.red[500],
                  color: '#fff',
                }}
                startIcon={<Delete />}
              >
                Remover
              </Button>
            </div>
          </Batches>
        </div>
      ))}
    </>
  );
};

export default BatcheItens;
