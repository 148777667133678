import React, { useState } from 'react';
import { Button, CardContent } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import history from '~/services/history';
import { useStyles } from './styles';
import api from '~/services/api';
import { ButtonProgress } from '../Campaign/Create/components/SaveCampaign/styles';
import { useParams } from 'react-router-dom';

function Unsubscribe () {
  const [loading, setLoading] = useState(false);

  const { email } = useParams();
  const utmsParams = new URLSearchParams(window.location.search)
  const utm_source = utmsParams.get('utm_source')
  const utm_medium = utmsParams.get('utm_medium')
  const utm_campaign = utmsParams.get('utm_campaign')

  const classes = useStyles();

  const save = () => {
    const data = {
      unsubscribe_email_form:{
        email: email,
        utm_medium: utm_medium,
        utm_source: utm_source,
        utm_campaign: utm_campaign,
      }
    };
    setLoading(true);
    api
      .post('/descadastrar/', data)
      .then((response) => {
        setLoading(false);
        const idEmail = response.data.id
        history.push(`/descadastrar/email/sucesso/${idEmail}`);
      })
      .catch(() => {
        history.push(`/descadastrar/email/sucesso/${email}`);
        setLoading(false);
      });
  };

  return (
    <Card className={classes.card}>
    <CardContent style={{padding: '0px'}}>
      <h1 className={classes.h1}>
      Você gostaria de cancelar a inscrição desses e-mails?
      </h1>
      <p className={classes.p}>
      Em até 24h horas seu email será excluído das nossas listas de envio.
      </p>
      <Button 
      variant="contained" 
      className={classes.button} 
      onClick={() => {
        save();
      }}
      >
        DESCADASTRAR
        {loading && <ButtonProgress size={24} />}
      </Button>
    </CardContent>
    </Card>
  );
}
export default Unsubscribe;
