import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';
import SignIn from '~/pages/SignIn';
import Defaultlayout from '~/pages/_layouts/default';
import AuthLayout from '~/pages/_layouts/auth';
import EmailLayout from '~/pages/_layouts/email';
// import SignUp from '~/pages/SignUp';

// import Profile from '~/pages/Profile';
// import Dashboard from '~/pages/Dashboard';
// import Users from '~/pages/Users';
import CampaignList from '~/pages/Campaign/List';
import CampaignCreate from '~/pages/Campaign/Create';
import CampaignDetail from '~/pages/Campaign/Detail';
import CampaignEdit from '~/pages/Campaign/Edit';
import Unsubscribe from '~/pages/Unsubscribe';
import SuccessUnsubscribe from '~/pages/Unsubscribe/SuccessUnsubscribe';
import Subscribe from '~/pages/Unsubscribe/Subscribe';

const Routes = () => {
  return (
    <Switch>
      <Route path='/' exact component={SignIn} layout={AuthLayout}/>
      {/* <Route path='/register' component={SignUp} />
      <Route path='/profile' component={Profile} isPrivate />
      <Route path='/dashboard' component={Dashboard} isPrivate />
      <Route path='/usuarios' component={Users} isPrivate /> */}
      <Route path='/campanhas' exact component={CampaignList} isPrivate layout={Defaultlayout}/>
      <Route
        path='/campanhas/nova'
        exact
        component={CampaignCreate}
        isPrivate
        layout={Defaultlayout}
      />
      <Route path='/campanhas/:id' exact component={CampaignDetail} isPrivate layout={Defaultlayout}/>
      <Route
        path='/campanhas/:id/editar'
        exact
        component={CampaignEdit}
        isPrivate
        layout={Defaultlayout}
      />
      <Route path='/descadastrar/email/:email' exact component={Unsubscribe} layout={EmailLayout}/>
      <Route path='/descadastrar/email/sucesso/:id' exact component={SuccessUnsubscribe} layout={EmailLayout}/>
      <Route path='/recadastrar/email/' exact component={Subscribe} layout={EmailLayout}/>
    </Switch>
  );
};

export default Routes;
