import React, { useEffect } from 'react';
import {  CardContent } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { useStyles } from '../styles';
import { makeStyles } from '@material-ui/styles';
import { useParams } from 'react-router-dom';

import history from '~/services/history';
import api from '~/services/api';

const styles = makeStyles(() => ({
  card: {
    alignItems: 'center',
    textAlign: 'left',
    padding: '16px 24px',
    height: 224,
    width: 434
  },
}));

const pageConfig = {
  route: "/descadastrar/email/",
}

function SuccessUnsubscribe () {
  const { id } = useParams();
  const classesStyles = styles();
  const classes = useStyles();

  useEffect(() => {
    window.history.pushState(pageConfig, "")
  })

  const handleDelete = () => {
    api
    .delete(`/descadastrar/${id}`)
    .then(() => {
      history.push(`/recadastrar/email/`);
    })
    .catch(() => {
      history.push(`/recadastrar/email/`);
    });
  };
  
  return (
    <Card className={classesStyles.card}>
    <CardContent style={{padding: '0px'}}>
      <h1 className={classes.h1}>
      Pronto! Você não receberá mais esses emails.
      </h1>
      <p className={classes.p}>
      Em até 24h horas seu email será excluído das nossas listas de envio.
      </p>
      <div className={classes.span}>
      <span>
      Você cancelou sua inscrição por acidente?{' '}
      <button type="button" className={classes.link}
          onClick={() => {
            handleDelete();
          }}>
        <span >Re-cadastrar</span>
      </button>
      </span>
      </div>
    </CardContent>
    </Card>
  );
}
export default SuccessUnsubscribe;
