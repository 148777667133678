import React from 'react';
import { CardHeader, Button } from '@material-ui/core';
import { AddCircleOutlineOutlined } from '@material-ui/icons';
import { HeaderBatches } from './styles';
import { addCampaignBatches } from '~/store/modules/campaign/actions';
import { useSelector, useDispatch } from 'react-redux';

const Header = () => {
  const campaign = useSelector((state) => state.campaign);
  const dispatch = useDispatch();
  const handleAddBatchese = () => {
    dispatch(
      addCampaignBatches([
        ...(campaign.batches || []),
        { message: '', contacts: [] },
      ])
    );
  };
  return (
    <HeaderBatches>
      <CardHeader title='Lotes' subheader='Lotes de envio' />
      <Button
        variant='contained'
        color='primary'
        component='span'
        onClick={handleAddBatchese}
        startIcon={<AddCircleOutlineOutlined />}
      >
        Novo Lote
      </Button>
    </HeaderBatches>
  );
};

export default Header;
