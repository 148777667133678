import produce from 'immer';
const reducers = {
  clearCampaign(state) {
    return produce(state, (draft) => {
      draft.name = '';
      draft.sendMethod = 'tim';
      draft.batches = [];
      draft.data = {};
    });
  },
  updateCampaignName(state, name) {
    return produce(state, (draft) => {
      draft.name = name;
    });
  },
  updateCampaignSendMethod(state, sendMethod) {
    return produce(state, (draft) => {
      draft.sendMethod = sendMethod;
    });
  },
  addCampaignBatches(state, batches) {
    return produce(state, (draft) => {
      draft.batches = batches;
    });
  },
  addCampaignNewBatches(state, batches) {
    return produce(state, (draft) => {
      draft.data.newBatches = batches;
    });
  },
  removeCampaignBatches(state, index) {
    return produce(state, (draft) => {
      draft.batches.splice(index, 1);
    });
  },
  removeCampaignNewBatches(state, index) {
    return produce(state, (draft) => {
      draft.data.newBatches.splice(index, 1);
    });
  },
  updateCampaignMsgBatchIndex(state, { index, message }) {
    return produce(state, (draft) => {
      draft.batches[index].message = message;
    });
  },
  updateCampaignMsgNewBatchIndex(state, { index, message }) {
    return produce(state, (draft) => {
      draft.data.newBatches[index].message = message;
    });
  },
  updateCampaignContactsBatchIndex(state, { index, contacts }) {
    return produce(state, (draft) => {
      draft.batches[index].contacts = contacts;
    });
  },
  updateCampaignContactsNewBatchIndex(state, { index, contacts }) {
    return produce(state, (draft) => {
      draft.data.newBatches[index].contacts = contacts;
    });
  },
  loadCampaign(state, campaign) {
    return produce(state, (draft) => {
      draft.data = campaign;
      draft.data.newBatches = [];
    });
  },
};
export default reducers;
