import reducers from './reducers';
const initialState = {};
const campaign = (state = initialState, action) => {
  const { type, payload } = action;
  const reducer = reducers[type];
  if (reducer) {
    return reducer(state, payload);
  }
  return state;
};
export default campaign;
