import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Skeleton } from '@material-ui/lab';
import statusConfig from '../../../statusConfig';

const Dashs = ({ campaign, loading }) => {
  const [dataChart, setDataChart] = useState({});
  const [optionChart, setOptionChart] = useState({});

  useEffect(() => {
    const loadLabel = () => {
      return Object.keys(campaign?.number_of_batches_send_to_by_status || {})
        .map(
          (status) =>
            campaign.number_of_batches_send_to_by_status[status] &&
            statusConfig[status].label
        )
        .filter((label) => label);
    };
    const loadData = () => {
      return Object.keys(campaign?.number_of_batches_send_to_by_status || {})
        .map((status) => campaign.number_of_batches_send_to_by_status[status])
        .filter((value) => value);
    };
    const loadColor = () => {
      return Object.keys(campaign?.number_of_batches_send_to_by_status || {})
        .map(
          (status) =>
            campaign.number_of_batches_send_to_by_status[status] &&
            statusConfig[status].color
        )
        .filter((color) => color);
    };

    const data = {
      labels: loadLabel(),
      datasets: [
        {
          data: loadData(),
          backgroundColor: loadColor(),
          hoverBackgroundColor: loadColor(),
        },
      ],
    };

    const options = {
      legend: {
        display: true,
        position: 'bottom',
      },
      responsive: true,
    };
    setDataChart(data);
    setOptionChart(options);
  }, [campaign]);

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        {loading ? (
          <>
            <Skeleton variant='circle' width={130} height={130} />
            <Skeleton variant='text' style={{ marginTop: '10px' }} />
          </>
        ) : (
          <Doughnut data={dataChart} options={optionChart} />
        )}
      </div>
    </>
  );
};

export default Dashs;
