import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TextField,
  InputAdornment,
  Tooltip,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { Search } from '@material-ui/icons';
import api from '~/services/api';
import { format, parseISO } from 'date-fns';
import statusConfig from '~/pages/Campaign/statusConfig';
const columns = [
  { label: 'Nome', id: 'name' },
  { label: 'Número', id: 'number' },
  { label: 'Data envio', id: 'dataSend' },
  { label: 'Lote', id: 'batche' },
  { label: 'status', id: 'status' },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: '25px',
    padding: '10px',
  },
  container: {
    maxHeight: 440,
  },
});
const ContactTable = ({ campaign }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [total, setTotal] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    const filterSearch = search.length > 1 ? `search=${search}` : '';
    api
      .get(
        `/destinatario?campaign_id=${campaign.id}&page=${
          page + 1
        }&items_per_page=${rowsPerPage}&${filterSearch}`
      )
      .then(({ data, headers }) => {
        setContacts(data.data);

        setTotal(parseInt(headers['x-total-count']));
        setRowsPerPage(parseInt(headers['x-per-page']));
        setLoading(false);
      });
  }, [campaign, rowsPerPage, page, search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  return (
    <Card className={classes.root}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingRight: '15px',
        }}
      >
        <CardHeader
          title='Contatos'
          subheader='Contatos para os quais foram enviadas as mensagens'
        />
        <TextField
          style={{ width: '25%' }}
          autoComplete='off'
          label='Buscar'
          helperText='Buscar por nome ou número'
          margin='dense'
          value={search}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            setSearch(e.target.value);
            setPage(0);
          }}
          required
          variant='outlined'
        />
      </div>
      <CardContent>
        <Paper>
          <TableContainer className={classes.container}>
            <Table size='small' stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id}>{column.label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading
                  ? [1, 2, 3, 5].map((item) => (
                      <TableRow hover role='checkbox' tabIndex={-1} key={item}>
                        <TableCell>
                          <Skeleton animation='wave' variant='text' />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation='wave' variant='text' />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation='wave' variant='text' />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation='wave' variant='text' />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation='wave' variant='text' />
                        </TableCell>
                      </TableRow>
                    ))
                  : contacts.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role='checkbox'
                          tabIndex={-1}
                          key={index}
                        >
                          <TableCell>{row.name}</TableCell>
                          <TableCell>
                            {row.phone.replace(
                              /(\d{2})(\d{2})(\d)(\d{4})(\d{4})/,
                              '$1 ($2) $3 $4-$5'
                            )}
                          </TableCell>
                          <TableCell>
                            {format(parseISO(row.created_at), 'dd/MM/yyyy')}
                          </TableCell>
                          <TableCell
                            style={{
                              textOverflow: 'ellipsis',
                              maxWidth: '380px',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                            }}
                          >
                            {row.batch_message}
                          </TableCell>
                          <TableCell
                            style={{ color: statusConfig[row.status].color }}
                          >
                            <Tooltip title={statusConfig[row.status].label}>
                              {statusConfig[row.status].icon}
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          {!loading && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component='div'
              count={total}
              labelRowsPerPage='itens por página'
              rowsPerPage={rowsPerPage}
              page={page}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to === -1 ? count : to} de ${
                  count !== -1 ? count : to
                }`
              }
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </CardContent>
    </Card>
  );
};

export default ContactTable;
